import createUI from '@duf/client-web';
import { init, trackEvent, setProperty } from './analytics';

window.onload = () => {
  const apiURL = process.env.API_URL;
  const targetsUrl = process.env.TARGETS_URL;
  const target = location.host;

  const manifestUrl = new URL(apiURL + '/manifest');
  const sp = manifestUrl.searchParams;

  sp.set('t', target);
  sp.set('ss', [window.screen.width, window.screen.height].join('|'));

  if (window.devicePixelRatio) sp.set('px', window.devicePixelRatio);
  if (window.navigator.language) sp.set('l', window.navigator.language);

  const platform =
    window.navigator.platform || window.navigator.userAgentData.platform;
  if (platform) sp.set('pl', platform);

  fetch(manifestUrl, { mode: 'cors', credentials: 'include' })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error('Failed to load manifest');
      }

      const manifest = await response.json();
      const { id, targetId, deploymentId, landingId, testId, testVariantId, artifactId, title, vars } = manifest;

      setProperty('title', title);
      setProperty('targetName', target);
      setProperty('targetId', targetId);
      setProperty('variantId', id);
      setProperty('deploymentId', deploymentId);
      setProperty('landingId', landingId);

      if (testId) {
        setProperty('testId', testId);

        if (testVariantId) {
          setProperty('testVariantId', testVariantId);
        }
      }

      init({});

      document.title = title;

      const variant = `${artifactId}-${id}`;
      const varsHash = manifest.vars ? JSON.stringify(manifest.vars) : '';

      if (
        window.localStorage.getItem('variant') !== variant ||
        window.localStorage.getItem('varsHash') !== varsHash // If test was updated
      ) {
        window.localStorage.setItem('variant', variant);
        window.localStorage.setItem('varsHash', varsHash);
        // TODO: make it as configuration to createUI so we do not need to know where and how to clear it
        window.sessionStorage.removeItem('duf-state');
      }

      const schemaResponse = await fetch(`${targetsUrl}/${artifactId}/schema.json`);
      const schema = await schemaResponse.json();

      createUI('#app', {
        schema,
        customVariables: vars,
        onNavigate: (screen) => trackEvent('navigate', { screen }),
        onOpenOverlay: (screen) => trackEvent('overlay_open', { screen }),
        onCloseOverlay: (screen) => trackEvent('overlay_close', { screen }),
        onReady: (app) => {
          const unsubscribe = app.subscribeToScreenEvents((eventName, event) => {
            const eventsToTrack = ['onClick', 'onSubmit'];
            if (eventsToTrack.includes(eventName)) {
              trackEvent(eventName, {
                target: {
                  id: event.target?.id,
                  tag: event.target?.tagName?.toLowerCase(),
                  class: event.target?.className,
                  text: event.target?.textContent?.substring?.(0, 100),
                },
                screen: app.currentOverlay?.id || app.currentScreen?.id,
              });
            }
          });

          const unsubscribeActions = app.subscribeToScreenActions((actionName, action, params) => {
            const actionsToTrack = ['SendDataAction'];
            if (actionsToTrack.includes(actionName)) {
              trackEvent(actionName, {
                url: params.url,
                method: params.method,
                data: params.data,
                screen: app.currentOverlay?.id || app.currentScreen?.id,
              });
            }
          });
        },
      });
    })
    .catch(console.error);
};
