const EVENT_FLUSH_INTERVAL = 10000; // 10 seconds
const EVENT_FLUSH_SIZE = 20;
const apiHost = process.env.API_URL + "/analytics"; // Analytics API URL

const eventBuffer = [];
const props = {};

export async function init() {
  setProperty("pageUrl", window.location.href);
  setProperty("referrer", document.referrer);
  setProperty("title", document.title);
  setProperty("languages", window.navigator.languages);
  setProperty("language", window.navigator.language);
  setProperty("platform", window.navigator.platform);
  setProperty("screenSize", [window.screen.width, window.screen.height]);
  setProperty("devicePixelRatio", window.devicePixelRatio);
  setProperty("networkType", navigator.connection.effectiveType);

  const sessionId = window.sessionStorage.getItem("a_sid");
  const userId = window.localStorage.getItem("a_uid");

  // Populate eventBuffer with props (for events that are tracked before init)
  eventBuffer.forEach((ev) => {
    ev.props = { ...props };
  });

  if (sessionId && userId) {
    setProperty("sessionId", sessionId);
    setProperty("userId", userId);
  } else {
    const res = await fetch(apiHost + "/init", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props),
    });

    const data = await res.json();

    setProperty("sessionId", data.sessionId);
    setProperty("userId", data.userId);

    window.sessionStorage.setItem("a_sid", data.sessionId);
    window.localStorage.setItem("a_uid", data.userId);
  }

  trackEvent("page_view");

  window.addEventListener("beforeunload", flush.bind(null, true));
  window.addEventListener("pagehide", flush.bind(null, false));
  window.addEventListener("load", () => {
    const performance = window.performance.toJSON();

    trackEvent("page_load", {
      time: performance.loadEventEnd - performance.navigationStart,
    });
  });

  setInterval(flush, EVENT_FLUSH_INTERVAL);
}

export function setProperty(key, value) {
  props[key] = value;
}

export function trackEvent(type, eventProps = {}) {
  eventBuffer.push({
    type,
    props: {
      ...props,
    },
    data: eventProps,
    timestamp: Date.now(),
  });

  if (eventBuffer.length >= EVENT_FLUSH_SIZE) {
    flush();
  }
}

export function flush(sync = false) {
  if (eventBuffer.length === 0) {
    return;
  }

  const events = eventBuffer.splice(0, eventBuffer.length);
  const body = new URLSearchParams();
  body.set("events", JSON.stringify(events));

  if (navigator.sendBeacon) {
    navigator.sendBeacon(apiHost + "/events", body);
    return;
  }

  if (sync) {
    const req = new XMLHttpRequest();
    req.open("POST", apiHost + "/events", false);
    req.send(body);
    return;
  }

  fetch(apiHost + "/events", {
    method: "POST",
    body,
  }).catch(console.error);
}
